<template>
    <div class="wrap">
        <div class="mg-auto" style="width: 1250px;">
            <div class="con_top">
                <h2 class="mb-20"><span>■</span>기업 계약 등록</h2>

                <div class="con">
                    <div class="con_table">
                        <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                            <tbody>
                                <tr>
                                    <th class="w-120px">계약시작일</th>
                                    <td class="left">
                                        <crm-datepicker class="ml-20" v-model="consStartDate"
                                            :parentDate="consStartDate" format="yyyy-MM-dd"
                                            :language="$store.state.ko"></crm-datepicker>
                                    </td>
                                    <th>계약종료일</th>
                                    <td class="left">
                                        <crm-datepicker class="ml-20" v-model="consEndDate" :parentDate="consEndDate"
                                            format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="w-120px">MSS<br/>(계약금액)</th>
                                    <td class="left">
                                        <input type="text" v-model.number="mssPrice" class="ml-20 w-200px"> 원
                                    </td>
                                    <th>프로그램<br/>(계약금액)</th>
                                    <td class="left">
                                        <input type="text" v-model.number="programPrice" class="ml-20 w-200px"> 원
                                    </td>
                                </tr>
                                <tr>
                                    <th class="w-120px">상담<br/>(총계약금액)</th>
                                    <td class="left">
                                        <input type="text" v-model.number="consPrice" class="ml-20 w-200px"> 원
                                    </td>
                                    <th class="w-120px">계약총액</th>
                                    <td class="left">
                                        <input type="text" v-model.number="totalPrice" class="ml-20 w-200px"> 원
                                    </td>
                                </tr>
                                <tr>
                                    <th>상담1회비용(대면)</th>
                                    <td class="left">
                                        <input type="text" v-model.number="interviewConsPrice" class="ml-20 w-200px"> 원
                                    </td>
                                    <th>상담1회비용(화상)</th>
                                    <td class="left">
                                        <input type="text" v-model.number="videoConsPrice" class="ml-20 w-200px"> 원
                                    </td>
                                </tr>
                                <tr>
                                    <th>상담1회비용(유선)</th>
                                    <td class="left">
                                        <input type="text" v-model.number="phoneConsPrice" class="ml-20 w-200px"> 원
                                    </td>
                                    <th class="w-120px">지원회기</th>
                                    <td class="left">
                                        <input type="text" v-model.number="consCount" class="ml-20 w-60px"> 회
                                    </td>
                                </tr>
                                <tr>
                                    <th class="w-120px">상담총회기</th>
                                    <td class="left">
                                        <input type="text" v-model.number="consTotalCount" class="ml-20 w-60px"> 회
                                    </td>
                                    <th>검사가능여부</th>
                                    <td class="left">
                                        <select v-model="testYn" class="ml-20 w-100px">
                                            <option value="Y">가능</option>
                                            <option value="N">불가능</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="w-120px">가족지원여부</th>
                                    <td class="left">
                                        <select v-model="familyYn" class="ml-20 w-100px">
                                            <option value="N">불가능</option>
                                            <option value="Y">가능</option>
                                        </select>
                                    </td>
                                    <th>유형</th>
                                    <td class="left">
                                        <label v-for="(item, index) of typeList" :key="index">
                                            <input type="checkbox" v-model="type" class="ml-20" :value="item">
                                            {{ item }}
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="btns3 mgB80">
                            <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                            <a class="btn_cancle mr-10 pointer" @click="submit()">등록</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import {
    GetFormatToday
} from '@/utils/common.js'
export default {

    data: () => ({
        crmCompanyIdx : 0,
        consStartDate: '', // 계약시작일
        consEndDate: '', // 계약종료일
        totalPrice: 0, // 계약총액
        consCount: 0, // 지원회기
        testYn: 'Y', // 검사가능여부 Y/N
        familyYn: 'N', // 가족지원여부 Y/N
        type: [], // 유형 (찾아가는상담/전화/화상/법률/채팅/대면상담)
        typeList: ['찾아가는상담','전화','화상','법률','채팅','대면상담'],
        btnDisabled: false, //등록버튼 활성화/비활성화 여부
        mssPrice: 0, // MSS 계약금액
        programPrice: 0, // 프로그램 계약금액
        consPrice: 0, // 상담 총계약금액
        consTotalCount: 0, // 상담총회기
        interviewConsPrice: 0, // 상담1회비용(대면)
        videoConsPrice: 0, // 상담1회비용(화상)	
        phoneConsPrice: 0, // 상담1회비용(유선)
    }),

    mounted() {

        this.crmCompanyIdx = this.$route.query.crmCompanyIdx
        if( !this.crmCompanyIdx ) {
            this.$router.push('/origin/company_manage')
        }
        // 계약일자-오늘 날짜를 default로
        this.consStartDate = GetFormatToday()
        this.consEndDate = GetFormatToday()
    },

    methods: {
        // 등록
        submit() {

            var vo = {
                crmCompanyIdx : this.crmCompanyIdx,
                consStartDate: this.consStartDate,
                consEndDate: this.consEndDate,
                totalPrice: this.totalPrice,
                consCount: this.consCount,
                testYn: this.testYn,
                familyYn: this.familyYn,
                type: this.type.join(','),
                mssPrice: this.mssPrice,
                programPrice: this.programPrice,
                consPrice: this.consPrice,
                consTotalCount: this.consTotalCount,
                interviewConsPrice: this.interviewConsPrice,
                videoConsPrice: this.videoConsPrice,
                phoneConsPrice: this.phoneConsPrice
            }
            if (this.btnDisabled) {
                alert('저장중입니다. 잠시만 기다려주세요.');
                return false;
            }

            this.btnDisabled = true;
            this.axios.post('/api/v1/companyContract', vo)
                .then(res => {
                    if (res.data.err === 0) {
                        alert('기업 계약 등록이 완료되었습니다.')
                        this.cancel()
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.btnDisabled = false;
                })
        },
        // 이전 페이지로 이동
        cancel() {
            this.$router.push({ name: 'origin-companyManageModify', query: { idx: this.crmCompanyIdx } })
        },

    }
}
</script>